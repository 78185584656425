<template>
  <div class="hero">
    <h1 class="mb-3">About</h1>
    <p>Just a little about my self</p>
    <social />
  </div>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style></style>
